import React, { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import s from './Warning.module.scss';
import foxLogo from '../../assets/images/fox-logo.png';
import { IoCloseSharp } from 'react-icons/io5';
import { useLocation, useHistory } from 'react-router-dom';

export const WarningContext = createContext(null);

const Warning = ({ children }) => {
    
    const location = useLocation();
    const history = useHistory();
    const [text, setText] = useState('');
    const [timeId, setTimeId] = useState(undefined);
    const [warningClass, setWarningClass] = useState([s.main]);

    useEffect(() => {

        const removeQueryParam = (param) => {
            const params = new URLSearchParams(location.search);
            params.delete(param);
            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            });
        };

        const params = new URLSearchParams(window?.location?.search);
        const successWarning = params?.get('successWarning');
        const failureWarning = params?.get('failureWarning');
        if (successWarning && successWarning !== text) {
            openWarning(successWarning, true);
            removeQueryParam('successWarning');
        }
        if (failureWarning && failureWarning !== text) {
            openWarning(failureWarning);
            removeQueryParam('failureWarning');
        }

    }, [text, location, history])

    useLayoutEffect(() => {
        const handleEvent = (e) => {
            if (e?.detail?.message && e?.detail?.message !== text) {
                openWarning(e.detail.message, e.detail.positive || false);
            }
        };
        window.addEventListener('snackbarEvent', handleEvent);
        return () => window.removeEventListener('snackbarEvent', () => {});
    }, [text])

    const openWarning = (message = '', positive = false, className = '', duration = message.length * 200) => {
        clearTimeout(timeId);
        setText('');
        setWarningClass([s.main]);
        
        setTimeout(() => {
            setWarningClass(c => [ ...c, className || '', positive ? s.positive : '' ]);
            setText(message);
            setTimeId(setTimeout(closeWarning, duration));
        }, 0);
    };

    const closeWarning = () => {
        setText('');
    };

    return <WarningContext.Provider value={{openWarning, closeWarning}}>
        {children}
        {text ? <p className={warningClass.join(' ')}>
            <img alt = 'ReviewFox' src = {foxLogo} className = {s.main_logo} />
            {text}
            <IoCloseSharp className = {s.main_close} onClick = {closeWarning} />
        </p> : <></>}
    </WarningContext.Provider>;
};

export default Warning;
export const withWarning = (ChildComponent) => {
    return class WarningComponent extends React.Component{
        static contextType = WarningContext;

        render(){
            return <ChildComponent 
                openWarning={this.context.openWarning}
                closeWarning={this.context.closeWarning}
                {...this.props}
            />;
        };
    };
};

export const useWarning = () => {
    const { openWarning, closeWarning } = useContext(WarningContext);
    return [openWarning, closeWarning];
};;