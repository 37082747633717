import { Provider } from 'react-redux';
import Routes from './routes';
import factory from './store';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = factory();
export const mainStore = store;

const App = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Routes />
			</PersistGate>
		</Provider>		
	);
}

export default App;
