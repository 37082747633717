import React, { useCallback, useState } from 'react';
import foxLogo from '../../assets/images/fox-logo.png';
import PrimaryHeading from '../../widgets/Heading/Heading';
import Form from '../../widgets/Form/Form';
import Column from '../../widgets/Column/Column';
import Row from '../../widgets/Row/Row';
import HyperLink from '../../widgets/HyperLink/HyperLink';
import s from './RegisterScreen.module.scss';
import { Divider } from 'antd';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import { useWarning } from '../../widgets/Warning/Warning';
import { simpleRequest } from '../../action';
import { useHistory } from 'react-router-dom';

const RegisterScreen = () => {

    const [warn] = useWarning();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const handleRegistering = useCallback(() => {
        if (!email || !/^(?!\.)(([\w\-]+\.)+[\w]+|([a-zA-Z]{1}|[\w]{2,}))@((([0-9]{1,3}\.){3}[0-9]{1,3})|(([\w\-]+\.)+[\w]{2,}))$/.test(email)) return warn('Please enter a valid email address.');
        if (!password || password.length < 6) return warn('Please enter a valid password.');
        return simpleRequest({ method: 'POST', body: { email, password }, url: '/users/create' })
            .then(response => warn(response.message, true));
    }, [warn, email, password]);

    return <main className = {s.main}>
        <Column className = {s.main_column}>
            <Column>
                <img alt = 'ReviewFox' src = {foxLogo} style = {{ height: '5rem' }} />
                <PrimaryHeading className = {s.main_heading} text = 'Get Started With ReviewFox' />
                <p style = {{ fontSize: '1.5rem', fontWeight: '500', color: '#666', margin: 0 }}>Create your free account</p>
            </Column>
            <Form
                cta = 'Register'
                format = {[
                    { type: 'text', uid: 'email', onChange: setEmail, value: email, placeholder: 'Your Email Address' },
                    { type: 'password', uid: 'pass', onChange: setPassword, value: password, placeholder: 'Create a strong password' },
                ]}
                onSubmit = {handleRegistering}
            />
            <div style = {{ fontSize: '1.5rem', fontWeight: '500', color: '#666', margin: 0, display: 'flex', columnGap: '.5rem' }}>
                <span>Already have an account?</span>
                <HyperLink onClick = {() => history.push('/login')} text = 'Login' />
            </div>
            <Divider style = {{ margin: '0' }}>OR</Divider>
            <Row style = {{ justifyContent: 'center', columnGap: '2rem' }}>
                <div onClick = {() => window.open(`${process.env.REACT_APP_SERVER_URL}/users/google-login`, '_self')} className = {s.main_block}><FcGoogle /></div>
                {/* <div className = {s.main_block}><FaFacebook color = '#1877F2' /></div> */}
            </Row>
        </Column>
    </main>
};

export default RegisterScreen;
