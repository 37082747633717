import React, { useCallback, useEffect, useState } from 'react';
import foxLogo from '../../assets/images/fox-logo.png';
import PrimaryHeading from '../../widgets/Heading/Heading';
import Form from '../../widgets/Form/Form';
import Column from '../../widgets/Column/Column';
import HyperLink from '../../widgets/HyperLink/HyperLink';
import s from './LoginScreen.module.scss';
import { Divider } from 'antd';
import Row from '../../widgets/Row/Row';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useWarning } from '../../widgets/Warning/Warning';
import { simpleRequest } from '../../action';
import { connect } from 'react-redux';
import { setUserAction } from '../../action/userAction';

const LoginScreen = ({ dispatch }) => {

    const [warn] = useWarning();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [canReset, setCanReset] = useState(false);
    const [isResetLoading, setIsResetLoading] = useState(false);
    const history = useHistory();

    const handleRegistering = useCallback(() => {
        if (!email || !/^(?!\.)(([\w\-]+\.)+[\w]+|([a-zA-Z]{1}|[\w]{2,}))@((([0-9]{1,3}\.){3}[0-9]{1,3})|(([\w\-]+\.)+[\w]{2,}))$/.test(email)) return warn('Please enter a valid email address.');
        if (!password || password.length < 6) return warn('Please enter a valid password.');
        return simpleRequest({ method: 'POST', body: { email, password }, url: '/users/login' })
            .then((response) => {
                if (!response) return setCanReset(true);
                setUserAction(response.data, dispatch);
                if (response.data.isBoarded === false) {
                    history.push('/onboard?currentStep=0');
                } else {
                    history.push('/home');
                }
            });
    }, [warn, email, password, history, dispatch]);

    const handleResetting = useCallback(() => {
        setIsResetLoading(true);
        if (!email || !/^(?!\.)(([\w\-]+\.)+[\w]+|([a-zA-Z]{1}|[\w]{2,}))@((([0-9]{1,3}\.){3}[0-9]{1,3})|(([\w\-]+\.)+[\w]{2,}))$/.test(email)) return warn('Please enter a valid email address.');
        return simpleRequest({ method: 'POST', body: { email }, url: '/users/start-reset', showMessage: true })
            .finally(() => setIsResetLoading(false));
    }, [warn, email]);

    useEffect(() => {
        const params = new URLSearchParams(window?.location?.search);
        let googleData = params?.get('googleData');
        if (googleData) {
           googleData = JSON.parse(googleData);
           setUserAction(googleData, dispatch);
           if (googleData.isBoarded === false) {
                history.push('/onboard?currentStep=0');
            } else {
                history.push('/home');
            }
        }
    }, [dispatch, history]);

    return <main className = {s.main}>
        <Column className = {s.main_column}>
            <Column>
                <img alt = 'ReviewFox' src = {foxLogo} style = {{ height: '5rem' }} />
                <PrimaryHeading className = {s.main_heading} text = 'Welcome Back!' />
                <p style = {{ fontSize: '1.5rem', fontWeight: '500', color: '#666', margin: 0 }}>Login to your account</p>
            </Column>
            <Form
                cta = 'Login'
                format = {[
                    { type: 'text', uid: 'email', onChange: setEmail, value: email, placeholder: 'Email Address' },
                    { type: 'password', uid: 'pass', onChange: setPassword, value: password, placeholder: 'Password' },
                ]}
                onSubmit = {handleRegistering}
            />
            <Column style = {{ rowGap: '.5rem' }}>
                {canReset ? <div style = {{ fontSize: '1.5rem', fontWeight: '500', color: '#666', margin: 0, display: 'flex', columnGap: '.5rem' }}>
                    <span>Forgot account password?</span>
                    <HyperLink loading = {isResetLoading} onClick = {handleResetting} text = 'Reset Password' />
                </div> : <></>}

                <div style = {{ fontSize: '1.5rem', fontWeight: '500', color: '#666', margin: 0, display: 'flex', columnGap: '.5rem' }}>
                    <span>Don't have an account?</span>
                    <HyperLink onClick = {() => history.push('/register')} text = 'Register' />
                </div>
                
                <Divider style = {{ margin: '2rem' }}>OR</Divider>
                <Row style = {{ justifyContent: 'center', columnGap: '2rem' }}>
                    <div onClick = {() => window.open(`${process.env.REACT_APP_SERVER_URL}/users/google-login`, '_self')} className = {s.main_block}><FcGoogle /></div>
                    {/* <div className = {s.main_block}><FaFacebook color = '#1877F2' /></div> */}
                </Row>
            </Column>
        </Column>
    </main>
};

const matchStateToProps = (state) => {
    return {};
}

export default connect(matchStateToProps)(LoginScreen);
