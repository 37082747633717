import React, { useCallback, useEffect, useState } from 'react';
import foxLogo from '../../assets/images/fox-logo.png';
import PrimaryHeading from '../../widgets/Heading/Heading';
import Form from '../../widgets/Form/Form';
import Column from '../../widgets/Column/Column';
import Row from '../../widgets/Row/Row';
import HyperLink from '../../widgets/HyperLink/HyperLink';
import s from './OnboardScreen.module.scss';
import { Divider } from 'antd';
import { FcGoogle } from 'react-icons/fc';
import { FaApple, FaFacebook } from 'react-icons/fa';
import { useWarning } from '../../widgets/Warning/Warning';
import { simpleRequest } from '../../action';
import { useHistory, useLocation } from 'react-router-dom';
import TwinTab from '../../widgets/Tab/Tab';
import PrimaryButton from '../../widgets/Button/Button';
import { connect } from 'react-redux';
import { setUserCompanyAction } from '../../action/userAction';

const OnboardScreen = ({ dispatch }) => {

    const location = useLocation();
    const [warn] = useWarning();
    const [currentStep, setCurrentStep] = useState(0);
    const [companyName, setCompanyName] = useState('');
    const [companyIndustry, setCompanyIndustry] = useState('');
    const [companyDetails, setCompanyDetails] = useState('');
    const [companyUrl, setCompanyUrl] = useState('');
    const [companyDocument, setCompanyDocument] = useState(null);
    const history = useHistory();

    useEffect(() => {
        
        const searchParams = new URLSearchParams(location.search);
        const currentStepValue = searchParams.get('currentStep');
        setCurrentStep((!currentStepValue || currentStepValue === '0') ? 0 : 1);

    }, [location]);

    const setNewStep = (step) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('currentStep', step);
        history.push({ pathname: location.pathname, search: searchParams.toString() });
    }

    const handleRegistering = useCallback(() => {
        const requestBody = { name: companyName, details: companyDetails, industry: companyIndustry, websiteUrl: companyUrl };
        simpleRequest({ method: 'POST', url: '/user-companies/add-details', body: requestBody });
        setNewStep(1);

    }, [companyName, companyDetails, companyIndustry, companyUrl]);

    const fetchCompany = () => {
        return simpleRequest({ method: 'GET', url: '/user-companies/get-details' })
            .then((response) => {
                if (response?.data) {
                    setCompanyName(response.data.name);
                    setCompanyIndustry(response.data.industry);
                    setCompanyDetails(response.data.details);
                    setCompanyUrl(response.data.websiteUrl);
                    setUserCompanyAction(response.data, dispatch);
                }
            });
    };

    useEffect(() => {
        fetchCompany();
    }, [location]);

    return <main className = {s.main}>
        <Column className = {s.main_column}>
            <Column style = {{ rowGap: '3rem' }}>
                <Column>
                    <img alt = 'ReviewFox' src = {foxLogo} style = {{ height: '5rem' }} />
                    <PrimaryHeading className = {s.main_heading} text = 'Get on board with ReviewFox' />
                    <p style = {{ fontSize: '1.5rem', fontWeight: '500', color: '#666', margin: 0 }}>Link your company with us</p>
                </Column>
                <TwinTab setter = {setNewStep} curr = {currentStep} A = 'Company Details' B = 'Social Details' forDesktop = {true} />
            </Column>
            {currentStep === 0 ? <Form
                cta = 'Proceed'
                onSkip = {() => setNewStep(1)}
                format = {[
                    [
                        { type: 'text', uid: 'name', onChange: setCompanyName, value: companyName, placeholder: 'Your Company Name' },
                        { type: 'select', uid: 'industry', options: [{ value: 'Restaurant', label: 'Restaurant' }], onChange: setCompanyIndustry, value: companyIndustry, placeholder: 'Your Company Industry' },
                    ],
                    { type: 'textarea', uid: 'details', onChange: setCompanyDetails, value: companyDetails, placeholder: 'Details about your company', rows: 5 },
                    [
                        { type: 'text', uid: 'url', onChange: setCompanyUrl, value: companyUrl, placeholder: 'Your Company Landing Page Url' },
                        // { type: 'file', uid: 'document', onChange: setCompanyDocument, value: companyDocument, placeholder: 'Your Company Document' },
                    ]
                ]}
                onSubmit = {handleRegistering}
            /> : <Column style = {{ rowGap: '2rem', flex: 1, justifyContent: 'space-between' }}>
                    <p>Please make sure to authenticate with your company's official account.</p>
                    <Column style = {{ rowGap: '2rem' }}>
                        <Row onClick = {new URLSearchParams(location.search)?.get('userGoogleEmail') ? () => {} : () => window.open(`${process.env.REACT_APP_SERVER_URL}/user-companies/google-login`, '_blank')} className = {s.main_social}>
                            <FcGoogle />
                            <Column>
                                <span>{new URLSearchParams(location.search)?.get('userGoogleEmail') ? 'Google Account Connected' : 'Connect Google Account'}</span>
                                <span className = {s.main_social_hint}>{new URLSearchParams(location.search)?.get('userGoogleEmail') || ''}</span>
                            </Column>
                        </Row>
                        <Row className = {s.main_social}>
                            <FaApple />
                            Connect Apple Account
                        </Row>
                    </Column>
                    <Row style = {{ justifyContent: 'space-between', padding: '0 1rem' }}>
                        <PrimaryButton onProceed = {() => history.push('/home')} text = 'Skip' className = {s.main_skip} />
                        <PrimaryButton onProceed = {() => history.push('/home')} text = 'Proceed' className = {s.main_continue} />
                    </Row>
            </Column>}
        </Column>
    </main>
};

export default connect()(OnboardScreen);
