import axis from 'axios';
import { ACCESS_TOKEN } from '../strings';
import { mainStore } from '../App';

const BASE = process.env.REACT_APP_SERVER_URL;
const axios = axis.create({ withCredentials: true });

const registerDeregisterApiEvent = (apiId, type) => {
    if (apiId) {
        const event = new CustomEvent('apiEvent', {
            detail: { type, apiId }
        });
        window.dispatchEvent(event);
    }
}

const triggerSnackbar = (message, positive = false) => {
    const event = new CustomEvent('snackbarEvent', { detail: { message, positive } });
    window.dispatchEvent(event);
}

export const simpleRequest = async ({
    method = 'post', body = {},
    url = '', apiId = '',
    showMessage = false,
}) => {  
    
    const config = {
        method,
        url: `${BASE}${url}`,
        data: body,
        headers: { 
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': true,
        }
    };

    const authToken = mainStore.getState()?.userReducer?.authToken;
    if (authToken) config.headers['Authorization'] = authToken;

    registerDeregisterApiEvent(apiId, 'add');
    try {
        const response = await axios(config);
        registerDeregisterApiEvent(apiId, 'remove');
        const payload = response.data;
        if (showMessage && payload?.message) {
            triggerSnackbar(payload.message, true);
        }
        return payload;
    } catch (err) {
        if (err?.status === 401) {
            window.location.replace(`${process.env.REACT_APP_DASHBOARD_URL}/login`);
            // window.location.reload();
            return;
        }
        registerDeregisterApiEvent(apiId, 'remove');
        triggerSnackbar(err?.response?.data?.message || err?.response?.data?.toString(), false);
    };
};

export const simpleFormRequest = ({
    body = {}, fileKey = 'file', 
    files, url = '', type = '',
    fileName = '',
}) => async dispatch => {
    const formData = new FormData();

    for(const key in body){
        formData.append(key, body[key]);
    }

    for(let i = 0; i < files?.length; i++){ 
        formData.append(fileKey, (files[i]), i + fileName);
    };

    const config = {
        method: 'post',
        url: `${BASE}${url}`,
        data: formData
    };

    try {
        const response = await axios(config);
        const payload = response.data;
        if (type) {
            dispatch({
                type,
                payload: {
                    ...payload,
                    success: true
                }
            });
        }
        return payload;
    } catch (err) {
        dispatch({
            type: 'APP_ERROR',
            payload: {
                success: false,
                ...err?.response?.data
            }
        });
    };
};