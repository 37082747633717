import React from 'react';
import { useHistory } from 'react-router-dom';

const WelcomeScreen = () => {

    const history = useHistory();
    return <>
    <header>
      <img src="./Logo.svg" alt="ReviewFox Logo" />
      <nav>
        <ul>
          <li>
            <a href="#">Products</a>
          </li>
          <li>
            <a href="#">Solutions</a>
          </li>
          <li>
            <a href="#">Resources</a>
          </li>
          <li>
            <a href="#">Pricing</a>
          </li>
        </ul>
      </nav>
      <div>
        <button className="btn" style={{ marginRight: '7.5px' }} onClick={() => history.push('/login')}>Login</button>
        <button className="btn herobtn" onClick={() => history.push('/register')}>Try ReviewFox Free</button>
      </div>
    </header>
    {/* Hero Section */}
    <div className="hero">
      <h1>Review Management Made Easy with AI</h1>
      <p>
        Manage your business' online reviews and reputation with reviewfox's
        highly contextual AI generated replies.
      </p>
      <button className="btn">Try ReviewFox Free</button>
      {/* App Store Icons */}
      <div className="app-icons">
        <img src="./apple.svg" alt="App Store Icon" />
        <img src="./play.svg" alt="Google Play Icon" />
        <img src="./google.svg" alt="Google Reviews Icon" />
      </div>
    </div>
    {/* Features Section */}
    <div className="features-section">
      <div className="feature-box" style={{ flexDirection: "row-reverse" }}>
        <img src="./stars.png" alt="Convert 1 Star to 5 Stars" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Convert 1 Star to 5 Stars</h3>
          <p>
            3 in 4 customers would update their negative review ratings if the
            business acknowledged and addressed their problems timely.
          </p>
          <button
            className="btn"
            style={{ width: "fit-content", padding: "10px 30px" }}
          >
            Try it now
          </button>
        </div>
      </div>
      <div className="feature-box">
        <img src="./galaxy.svg" alt="Enhance Reputation Icon" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Enhance your Reputation</h3>
          <p>
            More than 60% of internet users favor brands and businesses that
            respond to customer reviews, complaints, and requests online.
          </p>
          <button
            className="btn"
            style={{ width: "fit-content", padding: "10px 30px" }}
          >
            Try it now
          </button>
        </div>
      </div>
      <div className="feature-box" style={{ flexDirection: "row-reverse" }}>
        <img src="./mind.svg" alt="AI Insights Icon" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Get AI Insights into your Reviews</h3>
          <p>
            Let AI provide you the most informative insights into your reviews.
            Turn insights into actions to make your business better.
          </p>
          <button
            className="btn"
            style={{ width: "fit-content", padding: "10px 30px" }}
          >
            Let's Go
          </button>
        </div>
      </div>
      <div className="feature-box">
        <img src="./puzzle.svg" alt="Study Competition Icon" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Study your Competition and Industry</h3>
          <p>
            Use our AI tool to understand the pain points of your business,
            industry, and competition to stay ahead in the game.
          </p>
          <button
            className="btn"
            style={{ width: "fit-content", padding: "10px 30px" }}
          >
            Let's Go Now
          </button>
        </div>
      </div>
    </div>
    {/* Your Work, Everywhere Section */}
    <div className="work-section">
      <h2>Your work, everywhere you are</h2>
      <p>
        Access your dashboard from any device – Windows, macOS, Linux, iOS,
        Android. Stay connected to your data, wherever you are.
      </p>
      <button className="btn">Try Now</button>
    </div>
    {/* Data Section */}
    <div className="data-section">
      <div className="text-box">
        <h3>100% your data</h3>
        <p>
          The app is open source, and your notes are saved in an open format, so
          you always have access to them. End-To-End Encryption ensures your data
          is secure.
        </p>
        <button className="btn">Read more</button>
      </div>
      <div className="data-icons">
        <img src="./map.svg" alt="Encryption Icon" />
      </div>
    </div>
    {/* Hexagon Icons Section */}
    <div className="hexagon-wrapper">
      <img className="hexagon" src="./work.png" alt="TrustPilot Icon" />
    </div>
    {/* Testimonial Section */}
    <div className="testimonial-section">
      <h2>See what our trusted users say</h2>
      <div className="testimonial-wrapper">
        <div className="testimonial">
          <img src="https://via.placeholder.com/50" alt="User Image" />
          <p className="testimonial-text">
            "If you haven't tried ReviewFox yet, you need to give it a shot for
            your next event. It's so easy and intuitive to get a new event setup
            and if you need any help their customer service is seriously amazing."
          </p>
          <div className="author">Arnav</div>
          <div className="company">Founder, ReviewFox</div>
        </div>
        <div className="testimonial">
          <img src="https://via.placeholder.com/50" alt="User Image" />
          <p className="testimonial-text">
            "If you haven't tried ReviewFox yet, you need to give it a shot for
            your next event. It's so easy and intuitive to get a new event setup
            and if you need any help their customer service is seriously amazing."
          </p>
          <div className="author">Shorya</div>
          <div className="company">Founder, ReviewFox</div>
        </div>
        <div className="testimonial">
          <img src="https://via.placeholder.com/50" alt="User Image" />
          <p className="testimonial-text">
            "If you haven't tried ReviewFox yet, you need to give it a shot for
            your next event. It's so easy and intuitive to get a new event setup
            and if you need any help their customer service is seriously amazing."
          </p>
          <div className="author">Dinesh</div>
          <div className="company">Founder, ReviewFox</div>
        </div>
      </div>
    </div>
    {/* Footer Section */}
    <div className="footer">
      <div>
        <p>© 2024 ReviewFox. All rights reserved.</p>
      </div>
      <div>
        <a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a>
      </div>
    </div>
  </>
  
};

export default WelcomeScreen;
