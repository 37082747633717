import React from 'react';
import { LoadingDots } from '../Loader/Loader';

const mainStyle = {
    cursor: 'pointer', color: '#0071e3', fontWeight: 600, display: 'flex', 
}

const loadingStyle = {
    color: '#ccc', cursor: 'not-allowed'
}

const HyperLink = ({ text = '', loading = false, onClick = () => {} }) => {

    return <div style = {loading ? { ...mainStyle, ...loadingStyle } : mainStyle} onClick = {onClick}>
        {text} {loading ? <LoadingDots style = {{ marginLeft: '2.5rem', marginTop: '.5rem' }} /> : <></>}
    </div>

};

export default HyperLink;
