import React from 'react';

const Column = (props) => {

    return <div {...props} style = {{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', ...props.style }}>
        {props.children}
    </div>
};

export default Column;
