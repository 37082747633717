import React, { useState } from 'react';

import { LoadingDots } from '../Loader/Loader';
import TextInput, { TextArea, TextSelect } from '../Input/Input';
import PrimaryHeading from '../Heading/Heading';
import s from './Form.module.scss';
import Warning from '../Warning/Warning';
import Column from '../Column/Column';
import Row from '../Row/Row';
import PrimaryButton from '../Button/Button';

const DEFAULT_FORMAT = [
    { type: 'text', uid: 'name', onChange: () => {}, value: '', placeholder: 'Name' },
    { type: 'password', uid: 'pass', onChange: () => {}, value: '', placeholder: 'Password' },
]

const FormInput = ({ info }) => {

    const props = { ...info, containerClassName: s.form_main_input };
    if (info.type === 'select') return <TextSelect {...props} />
    if (info.type === 'textarea') return <TextArea {...props} />
    return <TextInput {...props} />
}

const Form = ({
    format = DEFAULT_FORMAT,
    cta='Submit',
    onSubmit = () => {}, error = '',
    heading = '', canSubmit = true,
    onSkip
}) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitForm = e => {
        e.preventDefault();
        if(canSubmit && !isSubmitting) {
            setIsSubmitting(true);
            const submitPromise = onSubmit(e);
            if (submitPromise instanceof Promise) {
                submitPromise?.finally(() => setIsSubmitting(false));
            } else {
                setIsSubmitting(false);
            }
            
        }
        return;
    };

    return <form className={s.form} onSubmit={submitForm}>
        <Warning text={error} />
        {heading ? <PrimaryHeading 
            text={heading}
            className={s.form_heading}
        /> : <></>}
        <Column className={s.form_main}>
            {
                format.map(info => {

                    if (Array.isArray(info)) {
                        return <Row>{info.map(subInfo => <FormInput info = {subInfo} key = {subInfo.uid} />)}</Row>
                    }

                    return <FormInput info = {info} key = {info.uid} />
                })
            }
        </Column>
        <div
            className={s.form_button}
        >
            <input
                type='submit' value={(canSubmit && !isSubmitting) ? cta : ''} onClick={submitForm}
                className={[s.form_main_button, (canSubmit && !isSubmitting) ? '' : s.inactive].join(' ')}
            />
            {(!canSubmit || isSubmitting) ? <LoadingDots 
                className={s.form_button_loading}
            /> : <></>}
        </div>
        {typeof onSkip === 'function' ? <PrimaryButton
            text = 'Skip' className = {s.form_skip} onProceed = {onSkip}
        /> : <></>}
    </form>
};

export default Form;