import {
    Route, Switch, 
    BrowserRouter as Router
} from 'react-router-dom';
import React from 'react';
import Gallery from './components/Gallery/Gallery';
import RegisterScreen from './screens/RegisterScreen/RegisterScreen';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import OnboardScreen from './screens/OnboardScreen/OnboardScreen';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import Warning from './widgets/Warning/Warning';
import WelcomeScreen from './screens/WelcomeScreen/WelcomeScreen';

const Routes = () => <Router>
    <Warning>
        <Switch>    
            <Route path='/gallery' component={Gallery} exact />
            <Route path='/' component={WelcomeScreen} exact />
            <Route path='/register' component={RegisterScreen} exact />
            <Route path='/login' component={LoginScreen} exact />
            <Route path='/onboard' component={OnboardScreen} exact />
            <Route path='/home' component={HomeScreen} exact />
        </Switch>
    </Warning>
</Router>;


export default Routes;