export const setUserAction = (data, dispatch) => {
    dispatch({
        type: 'USER_REDUCER',
        payload: {
            authToken: data.authToken,
            isBoarded: data.isBoarded,
            ...data.user,
        }
    });
};

export const setUserCompanyAction = (data, dispatch) => {
    dispatch({
        type: 'USER_COMPANY_REDUCER',
        payload: data
    });
};
