import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Send, Star, MessageSquare, ThumbsUp, ThumbsDown, Activity, Sparkles } from 'lucide-react';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const ReviewItem = ({ review }) => {
  const [replyText, setReplyText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const aiTemplates = [
    "Thank you for your feedback! We're glad you enjoyed [specific feature]. We're continuously working to improve and your input helps us a lot.",
    "We appreciate your review and apologize for any inconvenience. We'd love to hear more about [mentioned issue] to help us improve. Could you provide more details?"
  ];

  const handleReply = async () => {
    setIsLoading(true);
    setError(null);
    setTimeout(() => {
      setIsLoading(false);
      setReplyText('');
      console.log('Reply sent successfully');
    }, 1000);
  };

  const selectTemplate = (template) => {
    setReplyText(template);
  };

  return (
    <div className="p-4 bg-gray-800 rounded-lg shadow-lg">
      <div className="flex items-center justify-between mb-2">
        <h3 className="font-semibold text-lg">{review.title}</h3>
        <span className="text-yellow-400">{review.rating}</span>
      </div>
      <p className="text-gray-300 mb-4">{review.content}</p>
      <textarea
        className="w-full p-2 text-gray-900 bg-gray-100 rounded mb-2"
        rows={3}
        placeholder="Type your reply here..."
        value={replyText}
        onChange={(e) => setReplyText(e.target.value)}
        disabled={isLoading}
      ></textarea>
      <div className="flex justify-between items-center mb-4">
        <div>
          <button className="px-3 py-1 mr-2 text-sm bg-blue-500 rounded hover:bg-blue-600 transition-colors" disabled={isLoading}>Thank you</button>
          <button className="px-3 py-1 text-sm bg-green-500 rounded hover:bg-green-600 transition-colors" disabled={isLoading}>General positive</button>
        </div>
        <button 
          className="px-3 py-1 text-sm bg-indigo-500 rounded hover:bg-indigo-600 flex items-center transition-colors"
          onClick={handleReply}
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : (
            <>
              <Send size={16} className="mr-1" />
              Send reply
            </>
          )}
        </button>
      </div>
      <div>
        <p className="text-sm text-gray-400 mb-2">Pick a reviewfox.ai generated quick reply:</p>
        <div className="space-y-2">
          {aiTemplates.map((template, index) => (
            <button
              key={index}
              className="w-full p-2 text-left text-sm bg-indigo-600 hover:bg-indigo-700 rounded transition-colors flex items-center"
              onClick={() => selectTemplate(template)}
            >
              <Sparkles size={16} className="mr-2 flex-shrink-0" />
              <span className="line-clamp-2">{template}</span>
            </button>
          ))}
        </div>
      </div>
      {error && <p className="mt-2 text-red-500">{error}</p>}
    </div>
  );
};

const AppReputationIndex = ({ score }) => {
  return (
    <div className="p-6 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-lg shadow-lg flex items-center justify-between">
      <div>
        <h2 className="text-2xl font-bold mb-2 text-white">App Reputation Index</h2>
        <p className="text-sm text-blue-100">Based on review interactions and sentiment</p>
      </div>
      <div className="flex items-center bg-white bg-opacity-20 p-3 rounded-full">
        <span className="text-4xl font-bold mr-2 text-white">{score}</span>
        <Star className="text-yellow-300" size={28} fill="currentColor" />
      </div>
    </div>
  );
};

const StatCard = ({ title, value, icon: Icon }) => (
  <div className="p-6 bg-gray-800 rounded-lg shadow-lg">
    <div className="flex items-center justify-between mb-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      <Icon className="text-blue-400" size={24} />
    </div>
    <p className="text-3xl font-bold">{value}</p>
  </div>
);

const HomeScreen = () => {
  const [reputationScore, setReputationScore] = useState(0);
  
  // Mock data
  const totalReviews = 1234;
  const repliedReviews = 980;
  const positiveReviews = 950;
  const negativeReviews = 284;

  const reviews = [
    { id: 1, title: "Great app!", rating: "★★★★★", content: "This app is amazing. It has all the features I need." },
    { id: 2, title: "Needs improvement", rating: "★★★☆☆", content: "Good concept, but the UI could be more intuitive." },
  ];

  const ratingData = [
    { name: '1 Star', value: 50 },
    { name: '2 Stars', value: 100 },
    { name: '3 Stars', value: 234 },
    { name: '4 Stars', value: 350 },
    { name: '5 Stars', value: 500 },
  ];

  const sentimentData = [
    { name: 'Positive', value: positiveReviews },
    { name: 'Negative', value: negativeReviews },
  ];

  useEffect(() => {
    const replyRate = repliedReviews / totalReviews;
    const positiveRate = positiveReviews / totalReviews;
    const negativeRate = negativeReviews / totalReviews;
    let score = (replyRate * 4) + (positiveRate * 4) + ((1 - negativeRate) * 2);
    score = Math.min(Math.max(score, 0), 10);
    setReputationScore(parseFloat(score.toFixed(1)));
  }, [totalReviews, repliedReviews, positiveReviews, negativeReviews]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="flex h-screen bg-gray-900 text-white">
      <aside className="w-64 p-6 bg-gray-800">
        <h1 className="text-2xl font-bold mb-8">Review Dashboard</h1>
        <nav>
          <ul className="space-y-2">
            <li><a href="#" className="block p-2 hover:bg-gray-700 rounded transition-colors">Overview</a></li>
            <li><a href="#" className="block p-2 hover:bg-gray-700 rounded transition-colors">Reviews</a></li>
            <li><a href="#" className="block p-2 hover:bg-gray-700 rounded transition-colors">Analytics</a></li>
            <li><a href="#" className="block p-2 hover:bg-gray-700 rounded transition-colors">Settings</a></li>
          </ul>
        </nav>
      </aside>
      <main className="flex-1 p-8 overflow-y-auto">
        <div className="mb-8">
          <AppReputationIndex score={reputationScore} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <StatCard title="Total Reviews" value={totalReviews} icon={MessageSquare} />
          <StatCard title="Positive Reviews" value={positiveReviews} icon={ThumbsUp} />
          <StatCard title="Reply Rate" value={`${((repliedReviews / totalReviews) * 100).toFixed(1)}%`} icon={Activity} />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Rating Distribution</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={ratingData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Review Sentiment</h2>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={sentimentData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {sentimentData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Recent Reviews</h2>
          <div className="space-y-4">
            {reviews.map(review => (
              <ReviewItem key={review.id} review={review} />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomeScreen;
